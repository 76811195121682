.slidersh {
    height: max-content;
}
.btntext {
    background-color: #00b0db;
    color: black;
}
.btntext:hover {
    background-color: black;
    color: #E5A317;
}