.iconimg {
    width: 35px;
    height: 30px;
}
.whatsbtn {
    width: 160px;
    height: 40px;
    border-radius: 25px;
    background-color: #25d366;
}
.whatsbtn:hover {
    background-color: #1bc75a;
}
.txtss {
    text-decoration: none;
    color: white;
}