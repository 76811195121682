.bgcolor {
    background-color: black;
}
.headcolorss {
    color: black;
}

.headcolors {
   color: #00b0db;
}
.headcolori {
    color: #E5A317;
 }


 .divhead {
    margin: 0;
    padding: 0.5rem 0.5rem;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    z-index: 1;
  }
  
  .divhead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #00b0db, #bcd2e8);
    transform-origin: top right;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    z-index: -1;
  }
  
  .divhead:hover {
    color: black;
    transform: translateY(-2px);
  }
  
  .divhead:hover:before {
    transform: scaleX(1);
    transform-origin: top left;
  }
  
  .divhead.active:before {
    transform: scaleX(1);
    transform-origin: top left;
  }
  