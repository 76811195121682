.txtcolor {
    color: #E5A317;
}
.buttonstyles {
    background-color:#E5A317 ;
    color: black;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    height: 60px;
    width: 300px;
    
}
.buttonstyles:hover {
    background-color:black ;
    color: #E5A317;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    height: 60px;
    width: 300px;
    
}